import * as breadcrumbs from "./breadcrumbs.js";

const body = document.body;
const $pageContent = document.getElementsByClassName("page-content")[0];

const $history = $pageContent == null ? false : $pageContent.getElementsByClassName("history-slider")[0];
const $year = $pageContent == null ? false : $pageContent.getElementsByClassName("history-slider-year")[0];
//const $historyNav = $pageContent.getElementsByClassName("history__nav")[0];


// http://stackoverflow.com/questions/8889014/setting-vendor-prefixed-css-using-javascript
var transformProp = (function(){
  var testEl = document.createElement('div');
  if(testEl.style.transform == null) {
    var vendors = ['Webkit', 'Moz', 'ms'];
    for(var vendor in vendors) {
      if(testEl.style[ vendors[vendor] + 'Transform' ] !== undefined) {
        return vendors[vendor] + 'Transform';
      }
    }
  }
  return 'transform';
})();

function historySlider({el}){

  const html = `<div class='history-slider__outer-container'><div class='history-slider__container'>${el.innerHTML}</div></div>`;
  const $nav = document.createElement("UL");
  const navKeys = {};
  const $next = document.createElement("BUTTON");
  const $prev = document.createElement("BUTTON");
  const events = {};
  //const slides = Array.from(el.getElementsByTagName("article"));

  let windowTimeout;
  let windowWidth = window.innerWidth;
  let slides;
  let slidesLength;
  let currentSlideIndex = 0;
  let currentNavItem;
  let $cont;
  let active = null;


  // pub sub stuff
  //https://davidwalsh.name/pubsub-javascript

  function on(event, callback){
    if(!events.hasOwnProperty(event)){
      events[event] = [];
    }
    const i = events[event].push(callback) - 1;

    return{
      remove:function(){
        delete events[event][i];
      }
    }
  }

  function trigger(event, data){
    if(events.hasOwnProperty(event)){
      events[event].forEach(function(fn) {
        if(typeof fn === "function"){
          fn(data);
        }
      })
    }
  }


  function changeSlide(i) {
    const x = (100 / slidesLength) * i;
    if(i >= slidesLength) return;
    $cont.style[transformProp] = `translate3d(-${x}%, 0, 0)`;
    if(active != null){
      active.classList.remove("active");
    }
    slides[i].classList.add("active");
    active = slides[i];
  }

  // events
  //============================================================
  // function windowResize() {
  //   clearTimeout(windowTimeout);
  //
  //   windowTimeout = setTimeout(() => {
  //     windowWidth = window.innerWidth;
  //     //$cont.style.width = windowWidth * slides.length + "px";
  //     // slides.forEach(function(el) {
  //     //   el.style.width = windowWidth + "px";
  //     // });
  //   }, 50);
  //
  // }

  function hashChange(firstTime){
    //let index = navKeys.indexOf(window.location.hash);
    let hasHash = navKeys.hasOwnProperty(window.location.hash);
    let index;
    //let index = navKeys.hasOwnProperty(window.location.hash);

    if(hasHash){
      index = navKeys[window.location.hash].index;
    }
    else if(firstTime === true){
      index = 0;
    }


    if(hasHash || firstTime === true){
      let navElItem;
      if(index !== -1){
        if(index === 0){
          $prev.disabled = true;
        }
        else{
          $prev.disabled = false;
        }

        if(index + 1 === slidesLength){
          $next.disabled = true;
        }
        else{
          $next.disabled = false;
        }

        for ( let key in navKeys ){
          if(navKeys[key].index === index){
            if(currentNavItem){
              currentNavItem.classList.remove("history-slider__nav-item--active");
            }
            navKeys[key].navEl.classList.add("history-slider__nav-item--active");
            currentNavItem = navKeys[key].navEl;
            navElItem = navKeys[key];
            break;
          }
        }

        currentSlideIndex = index;
        trigger("changeSlide", {
          index,
          title:navElItem.title
        });
        changeSlide(index);
      }
    }
  }



  function nextButtonClick() {

    for ( let key in navKeys ){
      if(navKeys[key].index === currentSlideIndex + 1){
        window.location.hash = key;
        break;
      }
    }
    //window.location.hash = navKeys[(currentSlideIndex + 1)];
  }


  function prevButtonClick() {
    //window.location.hash = navKeys[(currentSlideIndex - 1)];
    for ( let key in navKeys ){
      if(navKeys[key].index === currentSlideIndex - 1){
        window.location.hash = key;
        break;
      }
    }
  }

  //============================================================




  function init() {

  el.innerHTML = html;
  slides = Array.from(el.getElementsByTagName("article"));
  if(slides.length === 0) throw "No slides";
  slidesLength = slides.length;
  $cont = el.getElementsByClassName("history-slider__container")[0];

  slides.forEach(function(el, i) {
    const $li = document.createElement("LI");
    const title = el.getAttribute("data-slider-title");
    const link = "#" + title.replace(/ /g, "-").replace(/'/g, "");

    $li.innerHTML = `<a href='${link}'><span>${title}</span></a>`;
    //el.style.width = windowWidth + "px";
    el.style.width = 100 / slidesLength + "%";
    navKeys[link] = {
      title,
      index:i,
      navEl:$li,
    };
    $nav.appendChild($li);
  });

  $cont.style.width = 100 * slidesLength + "%";
  $nav.classList.add("history-slider__nav");
  $next.classList.add("history-slider__next");
  $prev.classList.add("history-slider__prev");

  el.appendChild($next);
  el.appendChild($prev);
  el.appendChild($nav);

  hashChange(true);



  window.addEventListener("hashchange", hashChange);
  //window.addEventListener("resize", windowResize);
  $next.addEventListener("click", nextButtonClick);
  $prev.addEventListener("click", prevButtonClick);

  }
  //window.changeSlide = changeSlide;


  return{
    on,
    init
  };




}




function init() {
    if($history){

      const body = document.body;
      const style = document.createElement("STYLE");
      // const backgroundImages = [
      //   '/wp-content/themes/robertLangmead/img/home/business-photo-1366.jpg',
      //   '/wp-content/themes/robertLangmead/img/home/environment-1366.jpg',
      //   '/wp-content/themes/robertLangmead/img/home/about-1366.jpg',
      //   '/wp-content/themes/robertLangmead/img/home/business-photo-1366.jpg',
      //   '/wp-content/themes/robertLangmead/img/home/business-photo-1366.jpg',
      //   '/wp-content/themes/robertLangmead/img/home/business-photo-1366.jpg',
      //   '/wp-content/themes/robertLangmead/img/home/business-photo-1366.jpg',
      //   '/wp-content/themes/robertLangmead/img/home/business-photo-1366.jpg',
      // ];

      const backgroundImages = window.backgroundImages || [];
      const slider = historySlider({
        el:$history
      });

      let currentIndex = 0;
      body.appendChild(style);


      slider.on("changeSlide", function({index, title}) {
        const $img = document.createElement("IMG");
        currentIndex = index;
        $img.onload = function(){
          if(currentIndex === index){
            const isInit = breadcrumbs.isInit();
            if(isInit){
              breadcrumbs.setItems(function(items) {
                const lastItem = items[items.length - 1];
                if(lastItem.historyItem === true){
                  lastItem.url = `#${title.replace(/ /g, "-").replace(/'/g, "")}`;
                  lastItem.text = title;
                }
                else {
                  items.push({
                    url:`#${title.replace(/ /g, "-").replace(/'/g, "")}`,
                    text:title,
                    historyItem:true
                  });
                }
                return items;
              });

              // breadcrumbs.setItems(function(items) {
              //
              // });
            }
            style.innerHTML = `body:before{background-image:url("${backgroundImages[index]}");background-size:cover;background-repeat:no-repeat;}`;
            //body.style.backgroundImage = `url('${backgroundImages[index]}')`;

          }
        }
        $img.src = backgroundImages[index];
        $year.innerHTML = title;



      });

      slider.init();
    }
}


export default init;
