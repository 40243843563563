import raf from "raf";

const scrollArray = [];
const resizeArray = [];

let windowListeners = false;
let windowResizeTimeout;
let request = false;


function scrollSections({
  el,
  positionAdjustment = () => 0,
  sectionFn = function(){}
})
{
  if(el.nodeType !== 1) throw "el is not an element";
  else{
    const children = Array.from(el.children);
    const $nav = document.createElement("NAV");

    const $navA = children.reduce(function(ret, $el) {
      const id = $el.id;
      const $a = document.createElement("A");
      $a.href = "#" + id;
      $a.classList.add("scrollSections-nav__item");
      ret[id] = $a;
      return ret;
    }, {});

    const ids = children.reduce(function(ret, $el) {
      const id = $el.id;
      const $fakeEl = document.createElement("SPAN");
      $fakeEl.id = id;
      $fakeEl.classList.add("scrollSections-fake-item");
      el.insertBefore($fakeEl, $el);
      $el.id = $el.id + "-content";
      ret[id] = $el;
      return ret;
    }, {});



    const $fakePoints = children.reduce(function(ret, $el) {

    })

    let activeElement;
    let offsets = getOffsets();

    function scrollHandler() {
      let elKey;

      for ( let id in offsets ){

        const offset = offsets[id];

        if(window.pageYOffset >= offset){
          elKey = id;
        }
        else{
          break;
        }

      }
      if(activeElement === $navA[elKey]) return;

      if(activeElement){
        activeElement.classList.remove("scrollSections-nav__item--active");
      }
      $navA[elKey].classList.add("scrollSections-nav__item--active");
      activeElement = $navA[elKey];

      sectionFn(elKey);
    }

    function resizeHandler() {
      offsets = getOffsets();
      scrollHandler();
    }

    function getOffsets() {
      return children.reduce(function(ret, $el) {
        const pos = $el.getBoundingClientRect().top + window.pageYOffset + positionAdjustment($el);
        const id = $el.id.replace("-content", "");
        ret[id] = pos;
        return ret;
      },{});
    }


    for ( let key in $navA ){
      const $a = $navA[key];
      $nav.appendChild($a);
    }

    $nav.classList.add("scrollSections-nav");

    el.appendChild($nav);


    scrollArray.push(scrollHandler);
    resizeArray.push(resizeHandler);

    scrollHandler();

    if(windowListeners === false){
    window.addEventListener("scroll", windowScroll);
    window.addEventListener("resize", windowResize);
    }
  }
}


function onScroll() {

  scrollArray.forEach(function(fn) {
    fn();
  });

  request = false;
}


function windowScroll() {
  if(request === false){
    request = true;
    raf(onScroll);
  }
}


function windowResize() {
  windowResizeTimeout = setTimeout(function() {
    resizeArray.forEach(function(fn) {
      fn();
    });
  },50);
}








export default scrollSections;
