const body = document.body;

function init({toggle, nav, mobileBreakpoint = 767}) {

  const items = Array.from(nav.getElementsByClassName("menu-item-has-children"));

  items.forEach(function(item) {
    const children = Array.from(item.children);
    const a = children.filter(function(child) {
      if(child.tagName === "A") return true;
      return false;
    });

    if(a.length === 1){
      const href = a[0].getAttribute("href");
      if(href !== "#"){
        const ul = children.filter(function(child) {
          if(child.tagName === "UL") return true;
          return false;
        });

        if(ul.length === 1){
          const $li = document.createElement("LI");
          $li.innerHTML = `<a href="${href}">Continue to ${a[0].innerHTML}</a>`;
          $li.classList.add("mobile-menu-item");
          ul[0].insertBefore($li, ul[0].firstChild);
          //console.log(ul[0].innerHTML);
        }
      }
    }
  });

  function toggleClick(e) {
    body.classList.toggle("mobile-menu-open");
  }

  function itemClick(e) {
    const target = e.target;
    let current = target;
    //let li;

    while(current !== nav && current.tagName != "LI"){
      current = current.parentElement;
    }
    //console.log(current);
    if(window.innerWidth <= mobileBreakpoint && current.tagName === "LI" && current.classList.contains("menu-item-has-children")){
      current.classList.toggle("mobile-menu-item-open");
      e.preventDefault();
    }
    //console.log(target);
  }


  function documentClick(e) {
    const target = e.target;
    let current = target;
    let found = false;

    while(found === false && current.tagName !== "BODY" ){
      if(current === nav || current === toggle){
        found = true;
        break;
      }
      current = current.parentElement;
    }

    if(found === false && body.classList.contains("mobile-menu-open")){
      toggleClick();
    }


  }


  function documentTouchMove(e) {
    if(body.classList.contains("mobile-menu-open")){
      let found = false;
      let current = e.target;
      while(found === false && current.tagName !== "BODY" ){
        if(current === nav){
          found = true;
          break;
        }
        current = current.parentElement;
      }

      if(found === false) e.preventDefault();
      //e.preventDefault();
    }
  }


  toggle.addEventListener("click", toggleClick);
  nav.addEventListener("click", itemClick);
  document.addEventListener("click", documentClick);
  document.addEventListener("touchmove", documentTouchMove);

}



export default init;
