(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
(function (process){
// Generated by CoffeeScript 1.7.1
(function() {
  var getNanoSeconds, hrtime, loadTime;

  if ((typeof performance !== "undefined" && performance !== null) && performance.now) {
    module.exports = function() {
      return performance.now();
    };
  } else if ((typeof process !== "undefined" && process !== null) && process.hrtime) {
    module.exports = function() {
      return (getNanoSeconds() - loadTime) / 1e6;
    };
    hrtime = process.hrtime;
    getNanoSeconds = function() {
      var hr;
      hr = hrtime();
      return hr[0] * 1e9 + hr[1];
    };
    loadTime = getNanoSeconds();
  } else if (Date.now) {
    module.exports = function() {
      return Date.now() - loadTime;
    };
    loadTime = Date.now();
  } else {
    module.exports = function() {
      return new Date().getTime() - loadTime;
    };
    loadTime = new Date().getTime();
  }

}).call(this);

}).call(this,require('_process'))

},{"_process":2}],2:[function(require,module,exports){
// shim for using process in browser
var process = module.exports = {};

// cached from whatever global is present so that test runners that stub it
// don't break things.  But we need to wrap it in a try catch in case it is
// wrapped in strict mode code which doesn't define any globals.  It's inside a
// function because try/catches deoptimize in certain engines.

var cachedSetTimeout;
var cachedClearTimeout;

(function () {
    try {
        cachedSetTimeout = setTimeout;
    } catch (e) {
        cachedSetTimeout = function () {
            throw new Error('setTimeout is not defined');
        }
    }
    try {
        cachedClearTimeout = clearTimeout;
    } catch (e) {
        cachedClearTimeout = function () {
            throw new Error('clearTimeout is not defined');
        }
    }
} ())
function runTimeout(fun) {
    if (cachedSetTimeout === setTimeout) {
        return setTimeout(fun, 0);
    } else {
        return cachedSetTimeout.call(null, fun, 0);
    }
}
function runClearTimeout(marker) {
    if (cachedClearTimeout === clearTimeout) {
        clearTimeout(marker);
    } else {
        cachedClearTimeout.call(null, marker);
    }
}
var queue = [];
var draining = false;
var currentQueue;
var queueIndex = -1;

function cleanUpNextTick() {
    if (!draining || !currentQueue) {
        return;
    }
    draining = false;
    if (currentQueue.length) {
        queue = currentQueue.concat(queue);
    } else {
        queueIndex = -1;
    }
    if (queue.length) {
        drainQueue();
    }
}

function drainQueue() {
    if (draining) {
        return;
    }
    var timeout = runTimeout(cleanUpNextTick);
    draining = true;

    var len = queue.length;
    while(len) {
        currentQueue = queue;
        queue = [];
        while (++queueIndex < len) {
            if (currentQueue) {
                currentQueue[queueIndex].run();
            }
        }
        queueIndex = -1;
        len = queue.length;
    }
    currentQueue = null;
    draining = false;
    runClearTimeout(timeout);
}

process.nextTick = function (fun) {
    var args = new Array(arguments.length - 1);
    if (arguments.length > 1) {
        for (var i = 1; i < arguments.length; i++) {
            args[i - 1] = arguments[i];
        }
    }
    queue.push(new Item(fun, args));
    if (queue.length === 1 && !draining) {
        runTimeout(drainQueue);
    }
};

// v8 likes predictible objects
function Item(fun, array) {
    this.fun = fun;
    this.array = array;
}
Item.prototype.run = function () {
    this.fun.apply(null, this.array);
};
process.title = 'browser';
process.browser = true;
process.env = {};
process.argv = [];
process.version = ''; // empty string to avoid regexp issues
process.versions = {};

function noop() {}

process.on = noop;
process.addListener = noop;
process.once = noop;
process.off = noop;
process.removeListener = noop;
process.removeAllListeners = noop;
process.emit = noop;

process.binding = function (name) {
    throw new Error('process.binding is not supported');
};

process.cwd = function () { return '/' };
process.chdir = function (dir) {
    throw new Error('process.chdir is not supported');
};
process.umask = function() { return 0; };

},{}],3:[function(require,module,exports){
(function (global){
var now = require('performance-now')
  , root = typeof window === 'undefined' ? global : window
  , vendors = ['moz', 'webkit']
  , suffix = 'AnimationFrame'
  , raf = root['request' + suffix]
  , caf = root['cancel' + suffix] || root['cancelRequest' + suffix]

for(var i = 0; !raf && i < vendors.length; i++) {
  raf = root[vendors[i] + 'Request' + suffix]
  caf = root[vendors[i] + 'Cancel' + suffix]
      || root[vendors[i] + 'CancelRequest' + suffix]
}

// Some versions of FF have rAF but not cAF
if(!raf || !caf) {
  var last = 0
    , id = 0
    , queue = []
    , frameDuration = 1000 / 60

  raf = function(callback) {
    if(queue.length === 0) {
      var _now = now()
        , next = Math.max(0, frameDuration - (_now - last))
      last = next + _now
      setTimeout(function() {
        var cp = queue.slice(0)
        // Clear queue here to prevent
        // callbacks from appending listeners
        // to the current frame's queue
        queue.length = 0
        for(var i = 0; i < cp.length; i++) {
          if(!cp[i].cancelled) {
            try{
              cp[i].callback(last)
            } catch(e) {
              setTimeout(function() { throw e }, 0)
            }
          }
        }
      }, Math.round(next))
    }
    queue.push({
      handle: ++id,
      callback: callback,
      cancelled: false
    })
    return id
  }

  caf = function(handle) {
    for(var i = 0; i < queue.length; i++) {
      if(queue[i].handle === handle) {
        queue[i].cancelled = true
      }
    }
  }
}

module.exports = function(fn) {
  // Wrap in a new function to prevent
  // `cancel` potentially being assigned
  // to the native rAF function
  return raf.call(root, fn)
}
module.exports.cancel = function() {
  caf.apply(root, arguments)
}
module.exports.polyfill = function() {
  root.requestAnimationFrame = raf
  root.cancelAnimationFrame = caf
}

}).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})

},{"performance-now":1}],4:[function(require,module,exports){
'use strict';

if (!Array.from) {
  Array.from = function () {
    var toStr = Object.prototype.toString;
    var isCallable = function isCallable(fn) {
      return typeof fn === 'function' || toStr.call(fn) === '[object Function]';
    };
    var toInteger = function toInteger(value) {
      var number = Number(value);
      if (isNaN(number)) {
        return 0;
      }
      if (number === 0 || !isFinite(number)) {
        return number;
      }
      return (number > 0 ? 1 : -1) * Math.floor(Math.abs(number));
    };
    var maxSafeInteger = Math.pow(2, 53) - 1;
    var toLength = function toLength(value) {
      var len = toInteger(value);
      return Math.min(Math.max(len, 0), maxSafeInteger);
    };

    // The length property of the from method is 1.
    return function from(arrayLike /*, mapFn, thisArg */) {
      // 1. Let C be the this value.
      var C = this;

      // 2. Let items be ToObject(arrayLike).
      var items = Object(arrayLike);

      // 3. ReturnIfAbrupt(items).
      if (arrayLike == null) {
        throw new TypeError("Array.from requires an array-like object - not null or undefined");
      }

      // 4. If mapfn is undefined, then let mapping be false.
      var mapFn = arguments.length > 1 ? arguments[1] : void undefined;
      var T;
      if (typeof mapFn !== 'undefined') {
        // 5. else
        // 5. a If IsCallable(mapfn) is false, throw a TypeError exception.
        if (!isCallable(mapFn)) {
          throw new TypeError('Array.from: when provided, the second argument must be a function');
        }

        // 5. b. If thisArg was supplied, let T be thisArg; else let T be undefined.
        if (arguments.length > 2) {
          T = arguments[2];
        }
      }

      // 10. Let lenValue be Get(items, "length").
      // 11. Let len be ToLength(lenValue).
      var len = toLength(items.length);

      // 13. If IsConstructor(C) is true, then
      // 13. a. Let A be the result of calling the [[Construct]] internal method of C with an argument list containing the single item len.
      // 14. a. Else, Let A be ArrayCreate(len).
      var A = isCallable(C) ? Object(new C(len)) : new Array(len);

      // 16. Let k be 0.
      var k = 0;
      // 17. Repeat, while k < len… (also steps a - h)
      var kValue;
      while (k < len) {
        kValue = items[k];
        if (mapFn) {
          A[k] = typeof T === 'undefined' ? mapFn(kValue, k) : mapFn.call(T, kValue, k);
        } else {
          A[k] = kValue;
        }
        k += 1;
      }
      // 18. Let putStatus be Put(A, "length", len, true).
      A.length = len;
      // 20. Return A.
      return A;
    };
  }();
}

},{}],5:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});


function addImages(_ref) {
  var $el = _ref.el;
  var _ref$getSrc = _ref.getSrc;
  var getSrc = _ref$getSrc === undefined ? function (src) {
    return src;
  } : _ref$getSrc;

  var $style = document.createElement("STYLE");
  var length = $el.length || 1;
  var el = $el.length == null ? [$el] : $el;
  var arr = [];

  var styleHTML = "";

  for (var i = 0; i < length; i++) {
    var element = $el[i];
    var dataBackground = element.getAttribute("data-background");
    var className = "background-images-" + Date.now() + "-" + i;

    element.classList.add(className);

    if (dataBackground != null) {
      var obj = JSON.parse(dataBackground);
      for (var key in obj) {
        obj[key] = {
          selector: className,
          src: getSrc(obj[key])
        };
      }
      arr.push(obj);
    }
  }

  var mediaQueries = arr.reduce(function (ret, data) {

    var keys = Object.keys(data).sort();

    keys.forEach(function (key, i) {

      var prevValue = keys[i - 1] || 0;
      var prevKey = parseInt(prevValue) + 1 + "px";
      if (!ret.hasOwnProperty(prevKey)) {
        ret[prevKey] = [];
      }
      ret[prevKey].push({ selector: data[key].selector, src: data[key].src });
    });

    return ret;
  }, {});

  for (var _key in mediaQueries) {
    var images = mediaQueries[_key];
    //console.log(images);
    var string = "@media screen and (min-width:" + _key + "){\n        " + images.reduce(function (ret, _ref2) {
      var selector = _ref2.selector;
      var src = _ref2.src;

      ret += "." + selector + "{background-image:url(\"" + src + "\")}";
      return ret;
    }, "") + "\n      } ";

    styleHTML += string;
  }

  $style.innerHTML = styleHTML;

  document.body.appendChild($style);
}

exports.default = addImages;

},{}],6:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.init = init;
exports.setItems = setItems;
exports.isInit = isInit;
var body = document.body;
var main = document.getElementsByTagName("main")[0];
var h1 = main.getElementsByTagName("h1")[0];
var historySliderYearTitle = document.querySelector(".history-slider-year");

var isWhite = document.querySelector(".title--alternate") != null ? true : false;

var breadcrumbs = document.createElement("nav");
var breadcrumbsUl = document.createElement("ul");
var exclude = ["/"];

var items = void 0;
var init = false;

function init() {
  if (h1 && !body.classList.contains("error404") && exclude.indexOf(window.location.pathname) === -1) {
    var path = window.location.pathname.split("/").filter(function (str) {
      if (str.trim() == "") return false;
      return true;
    }).reduce(function (ret, arrItem, i) {

      var url = ret.length == 0 ? "/" + arrItem : ret[ret.length - 1].url + "/" + arrItem;
      var text = arrItem.split("-").map(function (word) {
        return word.split("").map(function (char, i) {
          if (i === 0) return char.toUpperCase();
          return char;
        }).join("");
      }).join(" ");

      var obj = {
        url: url,
        text: text
      };
      ret.push(obj);

      return ret;
    }, []);

    exports.init = init = true;

    path.unshift({ url: "/", text: "<svg xmlns=\"http://www.w3.org/2000/svg\" id=\"Layer_1\" viewBox=\"0 0 16.6 13.3\"><style>.breadcrumbs__home-icon{fill:#333333;}</style><path class=\"breadcrumbs__home-icon\" d=\"M16.4 6.9l-.6.7c-.1.1-.1.1-.2.1s-.2 0-.2-.1l-7-5.8-7 5.8c-.1.1-.2.1-.2.1-.1 0-.2 0-.2-.1l-.7-.7v-.3c0-.1 0-.2.1-.2l7.2-6c.2-.2.5-.3.8-.3s.5.1.8.3l2.5 2v-2c0-.1 0-.2.1-.2.1-.1.1-.1.2-.1h1.9c.1 0 .2 0 .2.1.1.1.1.1.1.2v4.1l2.2 1.8c.1.1.1.1.1.2 0 .2-.1.3-.1.4zm-2.3.7v4.8c0 .2-.1.3-.2.5-.1.1-.2.1-.4.1H9.6V9.2H7.1V13H3.2c-.2 0-.3 0-.4-.2-.1-.1-.2-.3-.2-.5V7.6l5.8-4.8 5.7 4.8c0-.1 0-.1 0 0z\"/></svg>" });

    items = path.map(function (obj) {
      return obj;
    });

    setItems(function (items) {
      return items;
    });

    breadcrumbs.classList.add("breadcrumbs");
    if (isWhite) {
      breadcrumbs.classList.add("breadcrumbs--white");
    } else {
      breadcrumbs.classList.add("breadcrumbs--default");
    }
    if (historySliderYearTitle === null) {
      h1.parentNode.insertBefore(breadcrumbs, h1.nextSibling);
    } else {
      historySliderYearTitle.parentNode.insertBefore(breadcrumbs, historySliderYearTitle.nextSibling);
    }
  }
}

function setItems(cb) {
  var innerItems = cb(items.map(function (obj) {
    return obj;
  }));
  breadcrumbsUl.innerHTML = "";
  innerItems.forEach(function (_ref) {
    var url = _ref.url;
    var text = _ref.text;

    var li = document.createElement("LI");
    li.classList.add("breadcrumbs__item");
    li.innerHTML = "<a href='" + url + "'>" + text + "</a>";
    breadcrumbsUl.appendChild(li);
  });
}

function isInit() {
  return init;
}

breadcrumbs.appendChild(breadcrumbsUl);

},{}],7:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _breadcrumbs = require("./breadcrumbs.js");

var breadcrumbs = _interopRequireWildcard(_breadcrumbs);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

var body = document.body;
var $pageContent = document.getElementsByClassName("page-content")[0];

var $history = $pageContent == null ? false : $pageContent.getElementsByClassName("history-slider")[0];
var $year = $pageContent == null ? false : $pageContent.getElementsByClassName("history-slider-year")[0];
//const $historyNav = $pageContent.getElementsByClassName("history__nav")[0];


// http://stackoverflow.com/questions/8889014/setting-vendor-prefixed-css-using-javascript
var transformProp = function () {
  var testEl = document.createElement('div');
  if (testEl.style.transform == null) {
    var vendors = ['Webkit', 'Moz', 'ms'];
    for (var vendor in vendors) {
      if (testEl.style[vendors[vendor] + 'Transform'] !== undefined) {
        return vendors[vendor] + 'Transform';
      }
    }
  }
  return 'transform';
}();

function historySlider(_ref) {
  var el = _ref.el;


  var html = "<div class='history-slider__outer-container'><div class='history-slider__container'>" + el.innerHTML + "</div></div>";
  var $nav = document.createElement("UL");
  var navKeys = {};
  var $next = document.createElement("BUTTON");
  var $prev = document.createElement("BUTTON");
  var events = {};
  //const slides = Array.from(el.getElementsByTagName("article"));

  var windowTimeout = void 0;
  var windowWidth = window.innerWidth;
  var slides = void 0;
  var slidesLength = void 0;
  var currentSlideIndex = 0;
  var currentNavItem = void 0;
  var $cont = void 0;
  var active = null;

  // pub sub stuff
  //https://davidwalsh.name/pubsub-javascript

  function on(event, callback) {
    if (!events.hasOwnProperty(event)) {
      events[event] = [];
    }
    var i = events[event].push(callback) - 1;

    return {
      remove: function remove() {
        delete events[event][i];
      }
    };
  }

  function trigger(event, data) {
    if (events.hasOwnProperty(event)) {
      events[event].forEach(function (fn) {
        if (typeof fn === "function") {
          fn(data);
        }
      });
    }
  }

  function changeSlide(i) {
    var x = 100 / slidesLength * i;
    if (i >= slidesLength) return;
    $cont.style[transformProp] = "translate3d(-" + x + "%, 0, 0)";
    if (active != null) {
      active.classList.remove("active");
    }
    slides[i].classList.add("active");
    active = slides[i];
  }

  // events
  //============================================================
  // function windowResize() {
  //   clearTimeout(windowTimeout);
  //
  //   windowTimeout = setTimeout(() => {
  //     windowWidth = window.innerWidth;
  //     //$cont.style.width = windowWidth * slides.length + "px";
  //     // slides.forEach(function(el) {
  //     //   el.style.width = windowWidth + "px";
  //     // });
  //   }, 50);
  //
  // }

  function hashChange(firstTime) {
    //let index = navKeys.indexOf(window.location.hash);
    var hasHash = navKeys.hasOwnProperty(window.location.hash);
    var index = void 0;
    //let index = navKeys.hasOwnProperty(window.location.hash);

    if (hasHash) {
      index = navKeys[window.location.hash].index;
    } else if (firstTime === true) {
      index = 0;
    }

    if (hasHash || firstTime === true) {
      var navElItem = void 0;
      if (index !== -1) {
        if (index === 0) {
          $prev.disabled = true;
        } else {
          $prev.disabled = false;
        }

        if (index + 1 === slidesLength) {
          $next.disabled = true;
        } else {
          $next.disabled = false;
        }

        for (var key in navKeys) {
          if (navKeys[key].index === index) {
            if (currentNavItem) {
              currentNavItem.classList.remove("history-slider__nav-item--active");
            }
            navKeys[key].navEl.classList.add("history-slider__nav-item--active");
            currentNavItem = navKeys[key].navEl;
            navElItem = navKeys[key];
            break;
          }
        }

        currentSlideIndex = index;
        trigger("changeSlide", {
          index: index,
          title: navElItem.title
        });
        changeSlide(index);
      }
    }
  }

  function nextButtonClick() {

    for (var key in navKeys) {
      if (navKeys[key].index === currentSlideIndex + 1) {
        window.location.hash = key;
        break;
      }
    }
    //window.location.hash = navKeys[(currentSlideIndex + 1)];
  }

  function prevButtonClick() {
    //window.location.hash = navKeys[(currentSlideIndex - 1)];
    for (var key in navKeys) {
      if (navKeys[key].index === currentSlideIndex - 1) {
        window.location.hash = key;
        break;
      }
    }
  }

  //============================================================


  function init() {

    el.innerHTML = html;
    slides = Array.from(el.getElementsByTagName("article"));
    if (slides.length === 0) throw "No slides";
    slidesLength = slides.length;
    $cont = el.getElementsByClassName("history-slider__container")[0];

    slides.forEach(function (el, i) {
      var $li = document.createElement("LI");
      var title = el.getAttribute("data-slider-title");
      var link = "#" + title.replace(/ /g, "-").replace(/'/g, "");

      $li.innerHTML = "<a href='" + link + "'><span>" + title + "</span></a>";
      //el.style.width = windowWidth + "px";
      el.style.width = 100 / slidesLength + "%";
      navKeys[link] = {
        title: title,
        index: i,
        navEl: $li
      };
      $nav.appendChild($li);
    });

    $cont.style.width = 100 * slidesLength + "%";
    $nav.classList.add("history-slider__nav");
    $next.classList.add("history-slider__next");
    $prev.classList.add("history-slider__prev");

    el.appendChild($next);
    el.appendChild($prev);
    el.appendChild($nav);

    hashChange(true);

    window.addEventListener("hashchange", hashChange);
    //window.addEventListener("resize", windowResize);
    $next.addEventListener("click", nextButtonClick);
    $prev.addEventListener("click", prevButtonClick);
  }
  //window.changeSlide = changeSlide;


  return {
    on: on,
    init: init
  };
}

function init() {
  if ($history) {
    (function () {

      var body = document.body;
      var style = document.createElement("STYLE");
      // const backgroundImages = [
      //   '/wp-content/themes/robertLangmead/img/home/business-photo-1366.jpg',
      //   '/wp-content/themes/robertLangmead/img/home/environment-1366.jpg',
      //   '/wp-content/themes/robertLangmead/img/home/about-1366.jpg',
      //   '/wp-content/themes/robertLangmead/img/home/business-photo-1366.jpg',
      //   '/wp-content/themes/robertLangmead/img/home/business-photo-1366.jpg',
      //   '/wp-content/themes/robertLangmead/img/home/business-photo-1366.jpg',
      //   '/wp-content/themes/robertLangmead/img/home/business-photo-1366.jpg',
      //   '/wp-content/themes/robertLangmead/img/home/business-photo-1366.jpg',
      // ];

      var backgroundImages = window.backgroundImages || [];
      var slider = historySlider({
        el: $history
      });

      var currentIndex = 0;
      body.appendChild(style);

      slider.on("changeSlide", function (_ref2) {
        var index = _ref2.index;
        var title = _ref2.title;

        var $img = document.createElement("IMG");
        currentIndex = index;
        $img.onload = function () {
          if (currentIndex === index) {
            var isInit = breadcrumbs.isInit();
            if (isInit) {
              breadcrumbs.setItems(function (items) {
                var lastItem = items[items.length - 1];
                if (lastItem.historyItem === true) {
                  lastItem.url = "#" + title.replace(/ /g, "-").replace(/'/g, "");
                  lastItem.text = title;
                } else {
                  items.push({
                    url: "#" + title.replace(/ /g, "-").replace(/'/g, ""),
                    text: title,
                    historyItem: true
                  });
                }
                return items;
              });

              // breadcrumbs.setItems(function(items) {
              //
              // });
            }
            style.innerHTML = "body:before{background-image:url(\"" + backgroundImages[index] + "\");background-size:cover;background-repeat:no-repeat;}";
            //body.style.backgroundImage = `url('${backgroundImages[index]}')`;
          }
        };
        $img.src = backgroundImages[index];
        $year.innerHTML = title;
      });

      slider.init();
    })();
  }
}

exports.default = init;

},{"./breadcrumbs.js":6}],8:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _backgroundImages = require("./backgroundImages.js");

var _backgroundImages2 = _interopRequireDefault(_backgroundImages);

var _scrollSections = require("./scrollSections.js");

var _scrollSections2 = _interopRequireDefault(_scrollSections);

var _settings = require("./settings.js");

var _settings2 = _interopRequireDefault(_settings);

var _raf = require("raf");

var _raf2 = _interopRequireDefault(_raf);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function init() {

  var $homePage = document.getElementsByClassName("home-page-content")[0];

  if ($homePage) {
    (function () {
      var getSectionHeight = function getSectionHeight() {
        return window.innerHeight - 66;
      };

      var onScroll = function onScroll(e) {

        var sectionHeight = getSectionHeight();
        var navHeight = $nav.clientHeight;
        var scrollHeight = sectionHeight * 4 - navHeight / 2 + 33;

        // if(e === true){
        //   if(pageYOffset >= scrollHeight && fixed === true){
        //     $nav.classList.add("absolute");
        //     $nav.style.top = `${(sectionHeight * 4) + (sectionHeight / 2)}px`;
        //     fixed = false;
        //   }
        //   else if(fixed === false && pageYOffset < scrollHeight){
        //     $nav.classList.remove("absolute");
        //     $nav.style.top = null;
        //     fixed = true;
        //   }
        // }

        if (pageYOffset >= scrollHeight /*&& fixed === true*/) {
            $nav.classList.add("absolute");
            $nav.style.top = sectionHeight * 4 + sectionHeight / 2 + "px";
            fixed = false;
          } else if ( /*fixed === false &&*/pageYOffset < scrollHeight) {
          $nav.classList.remove("absolute");
          $nav.style.top = null;
          fixed = true;
        }

        request = false;
      };

      var windowScroll = function windowScroll() {
        if (request === false) {
          request = true;
          (0, _raf2.default)(onScroll);
        }
      };

      var windowResize = function windowResize() {
        clearTimeout(windowResizeTimeout);
        windowResizeTimeout = setTimeout(function () {
          onScroll(true);
        }, 50);
      };

      var request = false;
      var windowResizeTimeout = void 0;
      var fixed = true;

      var $nav = void 0;

      (0, _backgroundImages2.default)({
        el: $homePage.children,
        getSrc: function getSrc(src) {
          return src.replace("THEME", _settings2.default.themeUrl);
        }
      });

      (0, _scrollSections2.default)({
        el: $homePage,
        positionAdjustment: function positionAdjustment() {
          var ret = -66 - window.innerHeight / 2;
          return ret;
        }
      });

      $nav = $homePage.getElementsByClassName("scrollSections-nav")[0];

      onScroll();
      window.addEventListener("scroll", windowScroll);
      window.addEventListener("resize", windowResize);
    })();
  }
}

exports.default = init;

},{"./backgroundImages.js":5,"./scrollSections.js":13,"./settings.js":14,"raf":3}],9:[function(require,module,exports){
"use strict";

require("./Array.From.js");

var _home = require("./home.js");

var _home2 = _interopRequireDefault(_home);

var _history = require("./history.js");

var _history2 = _interopRequireDefault(_history);

var _nav = require("./nav.js");

var _nav2 = _interopRequireDefault(_nav);

var _mobileMenu = require("./mobileMenu.js");

var _mobileMenu2 = _interopRequireDefault(_mobileMenu);

var _videos = require("./videos.js");

var _videos2 = _interopRequireDefault(_videos);

var _metaTheme = require("./metaTheme.js");

var _metaTheme2 = _interopRequireDefault(_metaTheme);

var _breadcrumbs = require("./breadcrumbs.js");

var breadcrumbs = _interopRequireWildcard(_breadcrumbs);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// //const $homePage = document.body.getElementsByClassName(".home-page")
// const $homePageContent = document.getElementsByClassName("home-page-content")[0];
//
// if($homePageContent)

var root = document.documentElement;

var flex = function flex() {

  var style = root.style;

  if ("flex" in style) return true;

  var prefixes = ["webkit", "ms"];

  for (var i = 0, len = prefixes.length; i < len; i++) {
    if (prefixes[i] + "Flex" in style) return true;
  }

  return false;
};

var errorMessage = false;

if (root.classList) {

  var $toggle = document.getElementsByClassName("header__mobile-toggle")[0];
  var $nav = document.getElementsByClassName("header__nav")[0];

  root.classList.remove("no-js");

  (0, _home2.default)();
  (0, _history2.default)();
  (0, _nav2.default)();
  (0, _mobileMenu2.default)({
    toggle: $toggle,
    nav: $nav,
    mobileBreakpoint: 830
  });
  (0, _videos2.default)();
  (0, _metaTheme2.default)();

  breadcrumbs.init();
} else {
  errorMessage = true;
}

if (flex === false) errorMessage = true;

if (errorMessage === true) {
  var $error = document.createElement("DIV");
  $error.id = "error-message";
  $error.innerHTML = "You are using an old browser. Please use a more up to date one to view this site as intended. <a href='http://outdatedbrowser.com/en' rel='nofollow'>http://outdatedbrowser.com/en</a>";
  document.body.appendChild($error);
}

},{"./Array.From.js":4,"./breadcrumbs.js":6,"./history.js":7,"./home.js":8,"./metaTheme.js":10,"./mobileMenu.js":11,"./nav.js":12,"./videos.js":15}],10:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
function init() {
  var body = document.body;

  var classes = [{
    modifier: "main",
    color: "#2a376b"
  }, {
    modifier: "business",
    color: "#333333"
  }, {
    modifier: "environment",
    color: "#EE9827"
  }, {
    modifier: "community",
    color: "#E81E4F"
  }, {
    modifier: "about",
    color: "#6B8399"
  }, {
    modifier: "media",
    color: "#998675"
  }, {
    modifier: "contact",
    color: "#997095"
  }];

  classes.forEach(function (_ref) {
    var modifier = _ref.modifier;
    var color = _ref.color;

    if (body.classList.contains("colour-scheme--" + modifier)) {
      document.getElementById("theme-colour-meta").setAttribute("content", color);
    }
  });
}

exports.default = init;

},{}],11:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var body = document.body;

function init(_ref) {
  var toggle = _ref.toggle;
  var nav = _ref.nav;
  var _ref$mobileBreakpoint = _ref.mobileBreakpoint;
  var mobileBreakpoint = _ref$mobileBreakpoint === undefined ? 767 : _ref$mobileBreakpoint;


  var items = Array.from(nav.getElementsByClassName("menu-item-has-children"));

  items.forEach(function (item) {
    var children = Array.from(item.children);
    var a = children.filter(function (child) {
      if (child.tagName === "A") return true;
      return false;
    });

    if (a.length === 1) {
      var href = a[0].getAttribute("href");
      if (href !== "#") {
        var ul = children.filter(function (child) {
          if (child.tagName === "UL") return true;
          return false;
        });

        if (ul.length === 1) {
          var $li = document.createElement("LI");
          $li.innerHTML = "<a href=\"" + href + "\">Continue to " + a[0].innerHTML + "</a>";
          $li.classList.add("mobile-menu-item");
          ul[0].insertBefore($li, ul[0].firstChild);
          //console.log(ul[0].innerHTML);
        }
      }
    }
  });

  function toggleClick(e) {
    body.classList.toggle("mobile-menu-open");
  }

  function itemClick(e) {
    var target = e.target;
    var current = target;
    //let li;

    while (current !== nav && current.tagName != "LI") {
      current = current.parentElement;
    }
    //console.log(current);
    if (window.innerWidth <= mobileBreakpoint && current.tagName === "LI" && current.classList.contains("menu-item-has-children")) {
      current.classList.toggle("mobile-menu-item-open");
      e.preventDefault();
    }
    //console.log(target);
  }

  function documentClick(e) {
    var target = e.target;
    var current = target;
    var found = false;

    while (found === false && current.tagName !== "BODY") {
      if (current === nav || current === toggle) {
        found = true;
        break;
      }
      current = current.parentElement;
    }

    if (found === false && body.classList.contains("mobile-menu-open")) {
      toggleClick();
    }
  }

  function documentTouchMove(e) {
    if (body.classList.contains("mobile-menu-open")) {
      var found = false;
      var current = e.target;
      while (found === false && current.tagName !== "BODY") {
        if (current === nav) {
          found = true;
          break;
        }
        current = current.parentElement;
      }

      if (found === false) e.preventDefault();
      //e.preventDefault();
    }
  }

  toggle.addEventListener("click", toggleClick);
  nav.addEventListener("click", itemClick);
  document.addEventListener("click", documentClick);
  document.addEventListener("touchmove", documentTouchMove);
}

exports.default = init;

},{}],12:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var $header = document.getElementsByClassName("header")[0];

function mouseEnter(e) {
  var self = this;
  var $ul = self.getElementsByTagName("ul")[0];
  var clientRect = $ul.getBoundingClientRect();
  var left = clientRect.left;
  var width = clientRect.width;
  var windowWidth = window.innerWidth;
  var isFullyVisible = left + width <= windowWidth;

  if (!isFullyVisible) {
    self.classList.add("menu-parent-other");
  } else {
    self.classList.remove("menu-parent-other");
  }
}

function mouseLeave(e) {
  var self = this;
  self.classList.remove("menu-parent-other");
}

function init() {

  var $parents = $header.getElementsByClassName("menu-item-has-children");
  var $array = Array.from($parents);

  $array.forEach(function ($el) {
    $el.addEventListener("mouseenter", mouseEnter);
    $el.addEventListener("mouseleave", mouseLeave);
  });
}

exports.default = init;

},{}],13:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _raf = require("raf");

var _raf2 = _interopRequireDefault(_raf);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var scrollArray = [];
var resizeArray = [];

var windowListeners = false;
var windowResizeTimeout = void 0;
var request = false;

function scrollSections(_ref) {
  var el = _ref.el;
  var _ref$positionAdjustme = _ref.positionAdjustment;
  var positionAdjustment = _ref$positionAdjustme === undefined ? function () {
    return 0;
  } : _ref$positionAdjustme;
  var _ref$sectionFn = _ref.sectionFn;
  var sectionFn = _ref$sectionFn === undefined ? function () {} : _ref$sectionFn;

  if (el.nodeType !== 1) throw "el is not an element";else {
    (function () {
      var scrollHandler = function scrollHandler() {
        var elKey = void 0;

        for (var id in offsets) {

          var offset = offsets[id];

          if (window.pageYOffset >= offset) {
            elKey = id;
          } else {
            break;
          }
        }
        if (activeElement === $navA[elKey]) return;

        if (activeElement) {
          activeElement.classList.remove("scrollSections-nav__item--active");
        }
        $navA[elKey].classList.add("scrollSections-nav__item--active");
        activeElement = $navA[elKey];

        sectionFn(elKey);
      };

      var resizeHandler = function resizeHandler() {
        offsets = getOffsets();
        scrollHandler();
      };

      var getOffsets = function getOffsets() {
        return children.reduce(function (ret, $el) {
          var pos = $el.getBoundingClientRect().top + window.pageYOffset + positionAdjustment($el);
          var id = $el.id.replace("-content", "");
          ret[id] = pos;
          return ret;
        }, {});
      };

      var children = Array.from(el.children);
      var $nav = document.createElement("NAV");

      var $navA = children.reduce(function (ret, $el) {
        var id = $el.id;
        var $a = document.createElement("A");
        $a.href = "#" + id;
        $a.classList.add("scrollSections-nav__item");
        ret[id] = $a;
        return ret;
      }, {});

      var ids = children.reduce(function (ret, $el) {
        var id = $el.id;
        var $fakeEl = document.createElement("SPAN");
        $fakeEl.id = id;
        $fakeEl.classList.add("scrollSections-fake-item");
        el.insertBefore($fakeEl, $el);
        $el.id = $el.id + "-content";
        ret[id] = $el;
        return ret;
      }, {});

      var $fakePoints = children.reduce(function (ret, $el) {});

      var activeElement = void 0;
      var offsets = getOffsets();

      for (var key in $navA) {
        var $a = $navA[key];
        $nav.appendChild($a);
      }

      $nav.classList.add("scrollSections-nav");

      el.appendChild($nav);

      scrollArray.push(scrollHandler);
      resizeArray.push(resizeHandler);

      scrollHandler();

      if (windowListeners === false) {
        window.addEventListener("scroll", windowScroll);
        window.addEventListener("resize", windowResize);
      }
    })();
  }
}

function onScroll() {

  scrollArray.forEach(function (fn) {
    fn();
  });

  request = false;
}

function windowScroll() {
  if (request === false) {
    request = true;
    (0, _raf2.default)(onScroll);
  }
}

function windowResize() {
  windowResizeTimeout = setTimeout(function () {
    resizeArray.forEach(function (fn) {
      fn();
    });
  }, 50);
}

exports.default = scrollSections;

},{"raf":3}],14:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var settings = {
  themeUrl: window.THEME
};

exports.default = settings;

},{}],15:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
function init() {

  var $videos = document.getElementsByClassName("video-page")[0] || false;
  var $nav = $videos === false ? false : $videos.getElementsByClassName("video-page__nav")[0];
  var $videoContainer = $videos === false ? false : $videos.getElementsByClassName("video-page__video__container")[0];

  var activeButton = void 0;

  function changeVideo(src) {
    $videoContainer.innerHTML = "<iframe src=\"" + src + "\" allowfullscreen></iframe>";
  }

  function navClick(e) {
    var target = e.target;
    var button = void 0;

    if (target.tagName === "LI") {
      button = target.getElementsByTagName("BUTTON")[0];
    } else if (target.tagName === "BUTTON") {
      button = target;
    }

    if (button) {
      activeButton.classList.remove("active");
      button.classList.add("active");
      activeButton = button;
      changeVideo(button.getAttribute("data-video-src"));
    }
  }

  if ($videos && $nav && $videoContainer) {
    var $li = Array.from($nav.getElementsByTagName("LI"));
    var first = $li[0].getElementsByTagName("BUTTON")[0];
    var src = first.getAttribute("data-video-src");

    first.classList.add("active");
    activeButton = first;
    //$videoContainer.innerHTML =

    changeVideo(src);

    $nav.addEventListener("click", navClick);
  }
}

exports.default = init;

},{}]},{},[9])

