import addBackgroundImage from "./backgroundImages.js";
import scrollSections from "./scrollSections.js";
import settings from "./settings.js";
import raf from "raf";



function init() {

  const $homePage = document.getElementsByClassName("home-page-content")[0];

  if($homePage){
    let request = false;
    let windowResizeTimeout;
    let fixed = true;

    let $nav;


    addBackgroundImage({
      el:$homePage.children,
      getSrc:function(src){
        return src.replace("THEME", settings.themeUrl);
      }
    });

    scrollSections({
      el:$homePage,
      positionAdjustment:function() {
        let ret = -66 - window.innerHeight / 2;
        return ret;
      }
    });

    $nav = $homePage.getElementsByClassName("scrollSections-nav")[0];


    function getSectionHeight() {
      return window.innerHeight - 66;
    }

    function onScroll(e) {


      const sectionHeight = getSectionHeight();
      const navHeight  = $nav.clientHeight;
      const scrollHeight = sectionHeight * 4 - (navHeight / 2) + 33;


      // if(e === true){
      //   if(pageYOffset >= scrollHeight && fixed === true){
      //     $nav.classList.add("absolute");
      //     $nav.style.top = `${(sectionHeight * 4) + (sectionHeight / 2)}px`;
      //     fixed = false;
      //   }
      //   else if(fixed === false && pageYOffset < scrollHeight){
      //     $nav.classList.remove("absolute");
      //     $nav.style.top = null;
      //     fixed = true;
      //   }
      // }

      if(pageYOffset >= scrollHeight /*&& fixed === true*/){
        $nav.classList.add("absolute");
        $nav.style.top = `${(sectionHeight * 4) + (sectionHeight / 2)}px`;
        fixed = false;
      }
      else if(/*fixed === false &&*/ pageYOffset < scrollHeight){
        $nav.classList.remove("absolute");
        $nav.style.top = null;
        fixed = true;
      }

      request = false;
    }


    function windowScroll() {
      if(request === false){
        request = true;
        raf(onScroll);
      }
    }

    function windowResize() {
      clearTimeout(windowResizeTimeout);
      windowResizeTimeout = setTimeout(() => {
        onScroll(true);
      }, 50);
    }

    onScroll();
    window.addEventListener("scroll", windowScroll);
    window.addEventListener("resize", windowResize);



  }

}



export default init;
