





function addImages({el:$el, getSrc = (src) => src}) {
    const $style = document.createElement("STYLE");
    const length = $el.length || 1;
    const el = $el.length == null ? [$el] : $el;
    const arr = [];

    let styleHTML = "";

    for ( let i = 0; i < length; i++){
      const element = $el[i];
      const dataBackground = element.getAttribute("data-background");
      const className = `background-images-${Date.now()}-${i}`;

      element.classList.add(className);

      if(dataBackground != null){
        const obj = JSON.parse(dataBackground);
        for ( let key in obj){
          obj[key] = {
            selector:className,
            src:getSrc(obj[key])
          }
        }
        arr.push(obj);
      }

    }

    const mediaQueries = arr.reduce(function(ret, data) {

      const keys = Object.keys(data).sort();

      keys.forEach(function(key, i) {

        const prevValue = keys[i -1] || 0;
        const prevKey = `${parseInt(prevValue) + 1}px`;
        if(!ret.hasOwnProperty(prevKey)){
          ret[prevKey] = [];
        }
        ret[prevKey].push({selector:data[key].selector,src:data[key].src});

      });

      return ret;
    },{});


    for ( let key in mediaQueries ){
      const images = mediaQueries[key];
      //console.log(images);
      const string = `@media screen and (min-width:${key}){
        ${images.reduce(function(ret, {selector, src}){
          ret += `.${selector}{background-image:url("${src}")}`;
          return ret;
        },"")}
      } `;

      styleHTML += string;
    }

    $style.innerHTML = styleHTML;


    document.body.appendChild($style);



}











export default addImages;
