function init() {

  const $videos = document.getElementsByClassName("video-page")[0] || false;
  const $nav = $videos === false ? false : $videos.getElementsByClassName("video-page__nav")[0];
  const $videoContainer = $videos === false ? false : $videos.getElementsByClassName("video-page__video__container")[0];

  let activeButton;

  function changeVideo(src) {
    $videoContainer.innerHTML = `<iframe src="${src}" allowfullscreen></iframe>`;
  }

  function navClick(e) {
    const target = e.target;
    let button;

    if(target.tagName === "LI"){
      button = target.getElementsByTagName("BUTTON")[0];
    }
    else if(target.tagName === "BUTTON"){
      button = target;
    }

    if(button){
      activeButton.classList.remove("active");
      button.classList.add("active");
      activeButton = button;
       changeVideo(button.getAttribute("data-video-src"));
    }
  }

  if($videos && $nav && $videoContainer){
    const $li = Array.from($nav.getElementsByTagName("LI"));
    const first = $li[0].getElementsByTagName("BUTTON")[0];
    const src = first.getAttribute("data-video-src");

    first.classList.add("active");
    activeButton = first;
    //$videoContainer.innerHTML =

    changeVideo(src);

    $nav.addEventListener("click", navClick);
  }
}

export default init;
