function init() {
  const body = document.body;

  const classes = [
    {
      modifier:"main",
      color:"#2a376b"
  }, {
    modifier:"business",
    color:"#333333"
  }, {
    modifier:"environment",
    color:"#EE9827"
  }, {
    modifier:"community",
    color:"#E81E4F"
  }, {
    modifier:"about",
    color:"#6B8399"
  }, {
    modifier:"media",
    color:"#998675"
  }, {
    modifier:"contact",
    color:"#997095"
  }];


  classes.forEach(function({modifier, color}) {
    if(body.classList.contains(`colour-scheme--${modifier}`)){
      document.getElementById("theme-colour-meta").setAttribute("content", color);
    }
  });


}

export default init;
