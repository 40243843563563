import "./Array.From.js";
import home from "./home.js";
import history from "./history.js";
import nav from "./nav.js";
import mobileMenu from "./mobileMenu.js";
import videos from "./videos.js";
import metaTheme from "./metaTheme.js";
import * as breadcrumbs from "./breadcrumbs.js";

// //const $homePage = document.body.getElementsByClassName(".home-page")
// const $homePageContent = document.getElementsByClassName("home-page-content")[0];
//
// if($homePageContent)

const root = document.documentElement;

const flex = (function(){

  const style = root.style;

  if("flex" in style) return true;

  const prefixes = ["webkit", "ms"];



  for ( let i = 0, len = prefixes.length; i < len; i++){
      if(`${prefixes[i]}Flex` in style) return true;
  }

  return false;


});

let errorMessage = false;

if(root.classList){

const $toggle = document.getElementsByClassName("header__mobile-toggle")[0];
const $nav = document.getElementsByClassName("header__nav")[0];

root.classList.remove("no-js");

home();
history();
nav();
mobileMenu({
  toggle:$toggle,
  nav:$nav,
  mobileBreakpoint:830
});
videos();
metaTheme();

breadcrumbs.init();

}
else{
  errorMessage = true;
}

if(flex === false) errorMessage = true;


if(errorMessage === true){
  const $error = document.createElement("DIV");
  $error.id = "error-message";
  $error.innerHTML = "You are using an old browser. Please use a more up to date one to view this site as intended. <a href='http://outdatedbrowser.com/en' rel='nofollow'>http://outdatedbrowser.com/en</a>";
  document.body.appendChild($error);
}
