const $header = document.getElementsByClassName("header")[0];

function mouseEnter(e) {
  const self = this;
  const $ul = self.getElementsByTagName("ul")[0];
  const clientRect = $ul.getBoundingClientRect();
  const left = clientRect.left;
  const width = clientRect.width;
  const windowWidth = window.innerWidth;
  const isFullyVisible = left + width <= windowWidth;

  if(!isFullyVisible){
    self.classList.add("menu-parent-other");
  }
  else{
    self.classList.remove("menu-parent-other");
  }
}

function mouseLeave(e) {
  const self = this;
  self.classList.remove("menu-parent-other");
}



function init() {

  const $parents = $header.getElementsByClassName("menu-item-has-children");
  const $array = Array.from($parents);

  $array.forEach(function($el) {
    $el.addEventListener("mouseenter", mouseEnter);
    $el.addEventListener("mouseleave", mouseLeave);
  });

}

export default init;
