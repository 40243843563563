const body = document.body;
const main = document.getElementsByTagName("main")[0];
const h1 = main.getElementsByTagName("h1")[0];
const historySliderYearTitle = document.querySelector(".history-slider-year");

const isWhite = document.querySelector(".title--alternate") != null ? true : false;


const breadcrumbs = document.createElement("nav");
const breadcrumbsUl = document.createElement("ul");
const exclude = ["/"];

let items;
let init = false;

export function init() {
    if (h1 && !body.classList.contains("error404") && exclude.indexOf(window.location.pathname) === -1) {
        const path = window.location.pathname
            .split("/")
            .filter(function(str) {
                if (str.trim() == "") return false;
                return true;
            })
            .reduce(function(ret, arrItem, i) {

                const url = ret.length == 0 ? `/${arrItem}` : `${ret[ret.length - 1].url}/${arrItem}`;
                const text = arrItem.split("-")
                                    .map(function(word) {
                                      return word.split("")
                                                 .map(function(char, i) {
                                                   if(i === 0) return char.toUpperCase();
                                                   return char;
                                                 })
                                                 .join("");
                                    })
                                    .join(" ");

                const obj = {
                  url,
                  text
                }
                ret.push(obj);

              return ret;
            }, []);

            init = true;

            path.unshift({url:"/", text:`<svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" viewBox="0 0 16.6 13.3"><style>.breadcrumbs__home-icon{fill:#333333;}</style><path class="breadcrumbs__home-icon" d="M16.4 6.9l-.6.7c-.1.1-.1.1-.2.1s-.2 0-.2-.1l-7-5.8-7 5.8c-.1.1-.2.1-.2.1-.1 0-.2 0-.2-.1l-.7-.7v-.3c0-.1 0-.2.1-.2l7.2-6c.2-.2.5-.3.8-.3s.5.1.8.3l2.5 2v-2c0-.1 0-.2.1-.2.1-.1.1-.1.2-.1h1.9c.1 0 .2 0 .2.1.1.1.1.1.1.2v4.1l2.2 1.8c.1.1.1.1.1.2 0 .2-.1.3-.1.4zm-2.3.7v4.8c0 .2-.1.3-.2.5-.1.1-.2.1-.4.1H9.6V9.2H7.1V13H3.2c-.2 0-.3 0-.4-.2-.1-.1-.2-.3-.2-.5V7.6l5.8-4.8 5.7 4.8c0-.1 0-.1 0 0z"/></svg>`});

            items = path.map(function(obj) {
              return obj;
            });

            setItems(function(items) {
              return items;
            });

            breadcrumbs.classList.add("breadcrumbs");
            if(isWhite){
              breadcrumbs.classList.add("breadcrumbs--white");
            }
            else{
              breadcrumbs.classList.add("breadcrumbs--default");
            }
            if(historySliderYearTitle === null){
              h1.parentNode.insertBefore(breadcrumbs, h1.nextSibling);
            }
            else{
              historySliderYearTitle.parentNode.insertBefore(breadcrumbs, historySliderYearTitle.nextSibling);
            }
    }
}


export function setItems(cb) {
  const innerItems = cb(items.map((obj) => obj));
  breadcrumbsUl.innerHTML = "";
  innerItems.forEach(function({url, text}) {
    const li = document.createElement("LI");
    li.classList.add("breadcrumbs__item")
    li.innerHTML = `<a href='${url}'>${text}</a>`;
    breadcrumbsUl.appendChild(li);
  });
}

export function isInit() {
  return init;
}



breadcrumbs.appendChild(breadcrumbsUl);
